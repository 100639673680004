import React from 'react';

import { OpenPositions } from '~/components/LandingPage/Exceptions/OpenPositions/OpenPositions';
import { LandingPageRoute } from '~/components/LandingPage/LandingPageRoute';
import { LandingPageSectionWrapper } from '~/components/LandingPage/LandingPageSectionWrapper';
import { LandingPageRouteProps } from '~/components/LandingPage/types';

// the careers page is a landing page at a special route with an extra component for open positions
export const CareersRoute: React.FC<LandingPageRouteProps> = (props) => (
  <LandingPageRoute {...props}>
    <LandingPageSectionWrapper componentName="open-positions">
      <OpenPositions />
    </LandingPageSectionWrapper>
  </LandingPageRoute>
);

export default CareersRoute;

export { getServerSideProps } from '~/components/LandingPage/Exceptions/getCareersServerSideProps';
